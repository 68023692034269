import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { ClientService } from '../client.service';

import { environment } from '../../environments/environment';

const URL = `${environment.apiUrl}/moh/convert`;

@Component({
  selector: 'app-centrex-audio',
  templateUrl: './centrex-audio.component.html',
  styleUrls: ['./centrex-audio.component.scss']
})
export class CentrexAudioComponent implements OnInit {
  public uploader:FileUploader = new FileUploader({
      url: URL,
      autoUpload: false,
      isHTML5: true,
      filters: [],
      removeAfterUpload: false,
      disableMultipart: false
    });
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
  client$: Observable<any[]>;
  selectedClient = [];

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }
  constructor(private clientservice: ClientService) {
  }

  ngOnInit() {
    console.log(this.uploader);
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; console.log(file); };
    this.uploader.onBeforeUploadItem = (item) => { item.withCredentials = false; console.log(item); };
    this.client$ = this.clientservice.getClientsId();
  }

  clearModel() {
      this.selectedClient = [];
  }

  changeModel(){}

}
