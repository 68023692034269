import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faExternalLinkAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  faExternalLinkAlt = faExternalLinkAlt;
  faSignOutAlt = faSignOutAlt;
  title: string = 'Intranet';
  show = false;

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  logout(){
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

}
