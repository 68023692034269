import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxDatatableModule, DatatableComponent } from '@swimlane/ngx-datatable';
import { faExternalLinkAlt, faPencilAlt, faSave, faTrashAlt, faExclamationTriangle, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular/';
import { saveAs } from 'file-saver';
import { environment } from '../../environments/environment';

import { ToastService } from '../services/toast/toast.service';

@Component({
  selector: 'app-devis',
  templateUrl: './devis.component.html',
  styleUrls: ['./devis.component.scss']
})
export class DevisComponent implements OnInit {
  ///// font-awesome icon
  faExternalLinkAlt = faExternalLinkAlt;
  faPencilAlt = faPencilAlt;
  faSave = faSave;
  faTrashAlt = faTrashAlt;
  faExclamationTriangle = faExclamationTriangle;
  faFileDownload = faFileDownload;
  /////
  public Editor = ClassicEditor;
  public Editor2 = ClassicEditor;
  @ViewChild( 'editorContext',{static: false} ) editorContextComponent: CKEditorComponent;
  @ViewChild( 'editorContext2',{static: false} ) editorContextComponent2: CKEditorComponent;
  editorContextComponentData = "";
  editorContextComponentData2 = "";
  editorContextDisable = false;
  editorContextDisable2 = false;
  SaveQuoteForm: FormGroup;
  ConfigQuoteForm: FormGroup;
  remiseControl: FormControl;
  actualUserIsModerator = false;

  grilleTarifChoice = [];
  selectedGrilleTarifChoice;
  quotePriceList = [];
  selectedItems = [];
  selectedTotalCategoryItems = [];
  selectedTotalAllCategoryItems = {
      "resalepricefas": 0,
      "buyfas": 0,
      "buyprice": 0,
      "resaleprice": 0,
      "resalepriceremise": 0
    };
  items = [];
  itemsArr = [];
  selected = [];
  prospects;
  selectedProspect;
  prospectsAdresse;
  prospectsAdresseFiltered;
  selectedProspectAdresse = [];
  quotes;
  quotesFiltered;
  editQuote;
  apiUrl;
  toasts: any[] = [];

  engagements = ["0", "12", "36", "48", "63"];
  pages = [{label:"fibre",category:"internet"}, {label:"grandstream",category:"telephonie"},
    {label:"yealink",category:"telephonie"},{label:"telephonieSpecifique",category:"telephonie"},
    {label:"interphone",category:"telephonie"},{label:"routeur",category:"internet"},
    {label:"operateurTelephonie",category:"telephonie"},{label:"cgvMobile",category:"mobile"},{label:"cgvInternet",category:"internet"}];
  selectedPages;
  selectedEngagment;

  @ViewChild('table',{static: false}) table: DatatableComponent;
  rows = [];
  isEditable = {};
  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, public toastService: ToastService) {}

  ngOnInit() {
    this.apiUrl = environment.apiUrl;
    this.selectedPages = this.pages;
    this.remiseControl = new FormControl("", [Validators.max(100), Validators.min(0)]);
    this.SaveQuoteForm = this.formBuilder.group({
    });
    this.ConfigQuoteForm = this.formBuilder.group({
      labelQuote: [null, Validators.required],
      engagement: [this.engagements[2], Validators.required],
      compact: [true, Validators.required],
      remiseTotal: [null]
    });
    this.getProspects();
    this.getProspectsSite();
    this.httpClient.get(`${environment.apiUrl}/quote/grid`)
    .subscribe((data) => {
        let arr = [];
        for (let item in data) {
          data[item].bindName = data[item].ref + " - " + data[item].label;
          arr.push(data[item]);
        }
        this.selectedGrilleTarifChoice = arr[0];
        this.grilleTarifChoice = arr;
        //console.log(this.grilleTarifChoice);
        this.httpClient
        .get(`${environment.apiUrl}/quote/pricelist?id=`+arr[0].idquotegrid)
        .subscribe(
          (data) => {
            let arr = [];
            for (let item in data) {
              data[item].bindName = data[item].ref + " - " + data[item].description;
              data[item].quantity = 1;
              arr.push(data[item]);
            }
            this.quotePriceList = arr;
            //console.log(this.quotePriceList);
          },
          (error) => {
            console.log('Erreur ! : ' + error.message);
          }
        );
      }, (error) => { console.log('Erreur ! : ' + error.message); }
    );
    this.httpClient
    .get(`${environment.apiUrl}/user/ismoderator`)
    .subscribe(
      (data) => {
        if(data['moderator'])
          this.actualUserIsModerator = true;
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
    this.getQuote();
    /*this.editorToolbarConfig = function( config ) {
    	config.toolbarGroups = [
    		{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
    		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
    		{ name: 'forms', groups: [ 'forms' ] },
    		'/',
    		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
    		{ name: 'links', groups: [ 'links' ] },
    		{ name: 'insert', groups: [ 'insert' ] },
    		'/',
    		{ name: 'styles', groups: [ 'styles' ] },
    		{ name: 'colors', groups: [ 'colors' ] },
    		{ name: 'tools', groups: [ 'tools' ] },
    		{ name: 'others', groups: [ 'others' ] },
    		{ name: 'about', groups: [ 'about' ] }
    	];

    	config.removeButtons = 'Save,Templates,SelectAll,Anchor,Unlink,Link,Image,Flash';
    };*/
  }

  showToast() {
    this.toasts.push('I am a success toast', { classname: 'bg-success text-light', delay: 10000 });
  }

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  removeToast(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  isValideRemise(remise){
    if(remise != null && remise != "" && remise != "0"){
      if(parseFloat(remise) > 100 || parseFloat(remise) < 0)
        return false;
    }
    return true;
  }

  getRowClass = (row) => {
    return {
      'category-2': row.idquoteservicecategory == 2,
      'category-3': row.idquoteservicecategory == 3,
      'category-4': row.idquoteservicecategory == 4,
      'category-5': row.idquoteservicecategory == 5,
    }
  }

  onChangeSelectGrille(e){
    //console.log(e);
    let row;
    this.httpClient
    .get(`${environment.apiUrl}/quote/pricelist?id=`+e.idquotegrid)
    .subscribe(
      (data) => {
        let arr = [];
        for (let item in data) {
          data[item].bindName = data[item].ref + " - " + data[item].description;
          data[item].quantity = 1;
          arr.push(data[item]);
          if(row = this.items.find(e => e.ref == data[item].ref)){
            row.resaleprice = data[item].resaleprice;
          }
        }
        this.quotePriceList = arr;
        //console.log(this.quotePriceList);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  onChangeSelectItem(e, idSite){
    //console.log(e, idSite);
    if(e.resalepricefas == null || e.resalepricefas == 0){
      e.resalepricefas = e.buyfas;
    }
    if(e.resaleprice == null || e.resaleprice == 0){
      e.resaleprice = (e.buyprice * this.selectedGrilleTarifChoice.defaultCoefficent).toFixed(2);
    }
    let items = this.itemsArr.find(a => a.site.idprospect_site == idSite);
    let item = items.quote.find(i => i.ref == e.ref);
    if(!item)
      items.quote.push({...e});
    else
      item.quantity++;
    items.quote = [...items.quote];
    this.itemsArr = [...this.itemsArr];
    //console.log(items.quote);
    //console.log(this.itemsArr);
    items.quote.sort(function(serviceA, serviceB){
        return serviceA.idquoteservicecategory.localeCompare(serviceB.idquoteservicecategory) || serviceA.ref.localeCompare(serviceB.ref);
    });
    this.sumCategory();
    this.selectedItems=[];
  }

  sumCategory(){
    this.selectedTotalCategoryItems = [];
    this.selectedTotalAllCategoryItems = {
      "resalepricefas": 0,
      "buyfas": 0,
      "buyprice": 0,
      "resaleprice": 0,
      "resalepriceremise": 0
    };
    let foundedItem;
    this.itemsArr.forEach((items) => {
      items.quote.forEach((item) => {
        if(foundedItem = this.selectedTotalCategoryItems.find(e => e.idquoteservicecategory == item.idquoteservicecategory)){
          let fas = (parseFloat(foundedItem.resalepricefas) + (parseFloat(item.resalepricefas) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.resalepricefas = (fas == "NaN")?0:fas;
          let buyfas = (parseFloat(foundedItem.buyfas) + (parseFloat(item.buyfas) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.buyfas = (buyfas == "NaN")?0:fas;
          let buyabo = (parseFloat(foundedItem.buyprice) + (parseFloat(item.buyprice) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.buyprice = (buyabo == "NaN")?0:buyabo;
          let abo = (parseFloat(foundedItem.resaleprice) + (parseFloat(item.resaleprice) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.resaleprice = (abo == "NaN")?0:abo;
          let resalepriceremise;
          if(item.remise != "")
            resalepriceremise = (parseFloat(foundedItem.resalepriceremise) + ((parseFloat(item.resaleprice) * parseFloat(item.quantity))  * (1 - (parseFloat(item.remise) / 100)) )).toFixed(2);
          else
            resalepriceremise = (parseFloat(foundedItem.resalepriceremise) + (parseFloat(item.resaleprice) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.resalepriceremise = (resalepriceremise == "NaN")?0:resalepriceremise;
        } else {
          this.selectedTotalCategoryItems.push({
            "idquoteservicecategory": item.idquoteservicecategory,
            "categoryname": item.categoryname,
            "resalepricefas": item.resalepricefas * item.quantity,
            "buyfas": item.buyfas * item.quantity,
            "buyprice": item.buyprice * item.quantity,
            "resaleprice": item.resaleprice * item.quantity,
            "resalepriceremise": (item.remise != "")?item.resaleprice * item.quantity * (1 - (item.remise / 100)):item.resaleprice * item.quantity
          });
        }
      });
    });
    //console.log(this.selectedTotalCategoryItems);
    this.selectedTotalCategoryItems.forEach((total) => {
      this.selectedTotalAllCategoryItems.resalepricefas = this.selectedTotalAllCategoryItems.resalepricefas + parseFloat(total.resalepricefas);
      this.selectedTotalAllCategoryItems.buyfas = this.selectedTotalAllCategoryItems.buyfas + parseFloat(total.buyfas);
      this.selectedTotalAllCategoryItems.buyprice = this.selectedTotalAllCategoryItems.buyprice + parseFloat(total.buyprice);
      this.selectedTotalAllCategoryItems.resaleprice = this.selectedTotalAllCategoryItems.resaleprice + parseFloat(total.resaleprice);
      this.selectedTotalAllCategoryItems.resalepriceremise = this.selectedTotalAllCategoryItems.resalepriceremise + parseFloat(total.resalepriceremise);
    });
  }

  haveRemiseCategory(){
    let have = false;
    this.selectedTotalCategoryItems.forEach((totalCat) => {
      if(parseFloat(totalCat.resaleprice) > totalCat.resalepriceremise) have = true;
    });
    return have;
  }

  getPercentTotalRemise(items){
    let total = "0";
    let totalWithRemise = "0";
    items.forEach(item => {
      total = (parseFloat(total) + (parseFloat(item.resaleprice) * parseFloat(item.quantity))).toFixed(2);
      if(item.remise == null) item.remise = "";
      if(item.remise != null && item.remise != "" && item.remise != "0"){
        //console.log("remise", ((parseFloat(item.resaleprice) * parseFloat(item.quantity)) * (1 - (parseFloat(item.remise) / 100))))
        totalWithRemise = (parseFloat(totalWithRemise) + ((parseFloat(item.resaleprice) * parseFloat(item.quantity)) * (1 - (parseFloat(item.remise) / 100))) ).toFixed(2);
      }
      else{
        totalWithRemise = (parseFloat(totalWithRemise) + (parseFloat(item.resaleprice) * parseFloat(item.quantity))).toFixed(2);
      }
    });
    return (100 - ((parseFloat(totalWithRemise) * 100) / parseFloat(total))).toFixed(2);
  }

  getTotalRemisePerCategorie(){
    let total = "0";
    let totalWithRemise = {};
    let foundedItem;

    this.itemsArr.forEach((items) => {
      items.quote.forEach((item) => {
        //console.log(item);
        if(foundedItem = this.selectedTotalCategoryItems.find(e => e.idquoteservicecategory == item.idquoteservicecategory)){
          let fas = (parseFloat(foundedItem.resalepricefas) + (parseFloat(item.resalepricefas) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.resalepricefas = (fas == "NaN")?0:fas;
          let buyfas = (parseFloat(foundedItem.buyfas) + (parseFloat(item.buyfas) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.buyfas = (buyfas == "NaN")?0:fas;
          let buyabo = (parseFloat(foundedItem.buyprice) + (parseFloat(item.buyprice) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.buyprice = (buyabo == "NaN")?0:buyabo;
          let abo = (parseFloat(foundedItem.resaleprice) + (parseFloat(item.resaleprice) * parseFloat(item.quantity))).toFixed(2);
          foundedItem.resaleprice = (abo == "NaN")?0:abo;
        } else {
          this.selectedTotalCategoryItems.push({
            "idquoteservicecategory": item.idquoteservicecategory,
            "categoryname": item.categoryname,
            "resalepricefas": item.resalepricefas * item.quantity,
            "buyfas": item.buyfas * item.quantity,
            "buyprice": item.buyprice * item.quantity,
            "resaleprice": item.resaleprice * item.quantity
          });
        }
      });
    });
  }

  onChangeQuantity(e){
    //console.log(e, this.items);
  }


  // Open/close panel
  toggleExpandRow(row, expanded) {
    this.table.rowDetail.toggleExpandRow(row);
    if(!expanded){
      this.table.rowDetail.collapseAllRows();
      this.table.rowDetail.toggleExpandRow(row);
    }
    else if (expanded){
      this.table.rowDetail.collapseAllRows();
    }
  }

  // Save row
  save(row, rowIndex, i){
    //console.log("editable", this.isEditable);
    this.isEditable[rowIndex]=false;
    //console.log("Row saved: "+ rowIndex, row, this.itemsArr[i], this.itemsArr);
    this.sumCategory();
  }

  // Delete row
  delete(row, rowIndex, i){
    this.isEditable[rowIndex]=!this.isEditable[rowIndex]
    //let items = this.itemsArr.find(a => a.site.idprospect_site == idSite);
    //let item = items.quote.find(i => i.ref == e.ref);
    this.itemsArr[i].quote.splice(rowIndex, 1);
    this.itemsArr[i].quote = [...this.itemsArr[i].quote];
    this.itemsArr = [...this.itemsArr];
    //console.log("Row deleted: "+ rowIndex, i, this.itemsArr[i]);
    this.sumCategory();
  }

  getProspects(){
    this.httpClient
    .get(`${environment.apiUrl}/prospect`)
    .subscribe(
      (data) => {
        this.prospects = data;
        this.prospects = [...this.prospects];
        //console.log(this.prospects);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  getProspectsSite(){
    this.httpClient
    .get(`${environment.apiUrl}/prospect/site`)
    .subscribe(
      (data) => {
        let arr = [];
        for (let item in data) {
          data[item].bindName = data[item].labelprospect_site + " - " + data[item].adressprospect_site;
          arr.push(data[item]);
        }
        this.prospectsAdresse = arr;
        this.prospectsAdresse = [...this.prospectsAdresse];
        //console.log(this.prospectsAdresse);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  getQuote(){
    this.httpClient
    .get(`${environment.apiUrl}/quote`)
    .subscribe(
      (data) => {
        this.quotes = data;
        this.quotes = [...this.quotes];
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  filterSite(){
    if(this.selectedProspect){
      let sites = this.prospectsAdresse.filter(v => v.idprospect == this.selectedProspect.idprospect);
      return sites;
    }
    return [];
  }

  onChangeSelectProspect(e){
    //console.log(e);
    this.prospectsAdresseFiltered = this.filterSite();
  }

  onChangeSelectProspectSite(e){
    let addr;
    if(!(e.length < this.itemsArr.length)){
      for(let s in e){
        if(addr = this.itemsArr.find(a => a.site.idprospect_site == e[s].idprospect_site)){
          console.log("adress before added");
        }
        else{
          console.log("push");
          this.itemsArr.push({"site": e[s], config: null, "quote": []});
        }
      }
    }
    else{
      this.itemsArr.forEach((quoteSite, i) => {
        let exist = e.find(element => element.idprospect_site == quoteSite.site.idprospect_site);
        if(!exist){
          this.itemsArr.splice(i, 1);
          this.itemsArr = [...this.itemsArr];
        }
      });
    }
  }

  onSubmitQuote(){
    let label = this.ConfigQuoteForm.value.labelQuote;
    this.itemsArr.forEach(quote=>{
      quote.config = {...this.ConfigQuoteForm.value};
      quote.config.haveRemiseCategory = this.haveRemiseCategory();
      delete quote.config.labelQuote;
    });

    this.itemsArr[0].config.pages = [];
    //console.log("selectedPages", this.selectedPages);
    this.selectedPages.forEach(page => {
      //console.log("page", page, page.label, page.category);
      if(page.label)
        this.itemsArr[0].config.pages.push(page.label);
      else if(page.category){
        let pagesCategory = this.pages.filter(function(d) {
          return (d.category && d.category.toLowerCase().indexOf(page.category) !== -1) || !page;
        });
        //console.log("cat", pagesCategory);
        pagesCategory.forEach(catPage=>{
          this.itemsArr[0].config.pages.push(catPage.label);
        });
      }
    });
    //console.log("pages", this.itemsArr[0].config.pages);
    if(this.editorContextComponent && this.editorContextComponent.editorInstance.getData())
      this.itemsArr[0].config.context = this.editorContextComponent.editorInstance.getData();
    if(this.editorContextComponent2 && this.editorContextComponent2.editorInstance.getData())
      this.itemsArr[0].config.context2 = this.editorContextComponent2.editorInstance.getData();
    //this.itemsArr[0].config.pages = this.selectedPages;
    //console.log("get ckeditor value", this.editorContextComponent.editorInstance.getData());
    //console.log(this.itemsArr);
    if(this.editQuote){
      this.httpClient
      .put(`${environment.apiUrl}/quote`, { "idquote": this.editQuote, "labelquote": label, "quote": this.itemsArr})
      .subscribe(
        (res) => {
          this.toastService.show('Enregistrement effectuer!', {
            classname: 'bg-success text-light',
            delay: 2000 ,
            autohide: true,
            headertext: 'Toast Header'
          });
          //this.editQuote = res[0].idprospect_quote;
          this.getQuote();
        },
        (error) => {
          this.toastService.show("Erreur d'enregistrement!", { classname: 'bg-danger text-light', delay: 15000 });
          console.log('Erreur ! : ' + error.message);
        }
      );
    }
    else{
      this.httpClient
      .post(`${environment.apiUrl}/quote`, {"labelquote": label, "quote": this.itemsArr})
      .subscribe(
        (res) => {
          this.getQuote();
          this.editQuote = res[0].idprospect_quote;
        },
        (error) => {
          console.log('Erreur ! : ' + error.message);
        }
      );
    }
  }

  getQuoteByIdQuote(val){
    return this.quotes.find(e => e.idprospect_quote == val);
  }

  editDevis(row){
    //console.log(row);
    this.editQuote = row.idprospect_quote;
    this.ConfigQuoteForm.controls["labelQuote"].setValue(row.labelquote);
    this.ConfigQuoteForm.controls["compact"].setValue(row.quoteinfo[0].config.compact);
    //this.selectedPages = row.quoteinfo[0].config.pages;
    this.editorContextComponentData = row.quoteinfo[0].config.context;
    this.editorContextComponentData2 = row.quoteinfo[0].config.context2;

    this.selectedPages = [];
    row.quoteinfo[0].config.pages.forEach(page => {
      let pagesCategory = this.pages.filter(function(d) {
        return (d.label && d.label.toLowerCase().indexOf(page.toLowerCase()) !== -1);
      });
      pagesCategory.forEach(catPage=>{
        this.selectedPages.push(catPage);
      });
    });
    this.selectedProspectAdresse = [];
    row.quoteinfo.forEach(quote => {
        //console.log(quote.site);
        this.selectedProspectAdresse.push(quote.site);
        /*if(!this.selectedProspectAdresse.find(a => a.idprospect_site == quote.site.idprospect_site)){
          this.selectedProspectAdresse.push(quote.site);
        }*/
    });
    //console.log("after force select", this.selectedProspectAdresse);
    this.itemsArr = row.quoteinfo;
    this.itemsArr = [...this.itemsArr];
    this.sumCategory();
    window.scroll(0,0);
  }

  generatePdf(id){
    this.toastService.show('Lancement du téléchargement ...', {
      classname: 'bg-success text-light',
      delay: 2000 ,
      autohide: true,
      headertext: 'Toast Header'
    });
    this.httpClient.get(`${environment.apiUrl}/quote/generate/pdf/`+id, {responseType: 'arraybuffer'})
    .subscribe(
      (data) => {
        this.toastService.show('Téléchargement terminé !', {
          classname: 'bg-success text-light',
          delay: 2000 ,
          autohide: true,
          headertext: 'Toast Header'
        });
        //console.log(data);
        let blob = new Blob([data], { type: 'application/pdf'});
        const file = new File([blob], 'devis_' + id + '.pdf', { type: 'application/pdf' });
        saveAs(file);
        //let url = window.URL.createObjectURL(blob);
        /*let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert( 'Please disable your Pop-up blocker and try again.');
        }*/
      },
      (error) => {
        this.toastService.show("Erreur de récupération du PDF!", { classname: 'bg-danger text-light', delay: 15000 });
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  clearDevis(){
    //console.log("clear");
    this.editQuote = null;
    this.ConfigQuoteForm.controls["labelQuote"].setValue(null);
    this.ConfigQuoteForm.controls["compact"].setValue(true);
    this.editorContextComponentData = "";
    this.editorContextComponentData = "";
    this.selectedProspectAdresse = [];
    this.itemsArr = [];
    this.itemsArr = [...this.itemsArr];
    this.selectedTotalCategoryItems = [];
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.quotes.filter(function(d) {
      return (d.companynameprospect && d.companynameprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.lastnameprospect && d.lastnameprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.firstnameprospect && d.firstnameprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.mailprospect && d.mailprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.siretnumberprospect && d.siretnumberprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.nafnumberprospect && d.nafnumberprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.idprospect && d.idprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.idprospect_quote && d.idprospect_quote.toLowerCase().indexOf(val) !== -1) ||
      (d.nafnumberprospect && d.nafnumberprospect.toLowerCase().indexOf(val) !== -1) ||
      (d.labelquote && d.labelquote.toLowerCase().indexOf(val) !== -1) || !val;
    });

    // update the rows
    this.quotesFiltered = temp;
  }

}
