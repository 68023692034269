import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './services/authentication/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    '../../node_modules/bootstrap/scss/bootstrap.scss',
    '../../node_modules/font-awesome/scss/font-awesome.scss'
  ]
})
export class AppComponent {
  title = 'Intranet';

  constructor(public authenticationService: AuthenticationService) { }
}
