import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { StockComponent } from './stock/stock.component';
import { NavComponent } from './nav/nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AboutComponent } from './about/about.component';
import { StockeditComponent } from './stockedit/stockedit.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StockeditmodelComponent } from './stockeditmodel/stockeditmodel.component';
import { StockaddComponent } from './stockadd/stockadd.component';
import { StockaddmodelComponent } from './stockaddmodel/stockaddmodel.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChartsModule } from 'ng2-charts';
import { ImportConfigComponent } from './import-config/import-config.component';
import { CentrexAudioComponent } from './centrex-audio/centrex-audio.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DevisComponent } from './devis/devis.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProspectComponent } from './prospect/prospect.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FileDropDirective } from './file-drop.directive';
//import { AuthenticationService }          from './services/authentication/authentication.service';
//import { ToastService }          from './services/toast/toast.service';
import { JwtInterceptor } from './helpers/jwt/jwt.interceptor';
import { LoginComponent } from './login/login.component';
import { ListInterfacesComponent } from './list-interfaces/list-interfaces.component';
import { ToastsContainer } from './toast/toasts-container.component';

const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };

@NgModule({
  declarations: [
    AppComponent,
    StockComponent,
    NavComponent,
    DashboardComponent,
    AboutComponent,
    StockeditComponent,
    StockeditmodelComponent,
    StockaddComponent,
    StockaddmodelComponent,
    ImportConfigComponent,
    CentrexAudioComponent,
    DevisComponent,
    ProspectComponent,
    LoginComponent,
    FileDropDirective,
    ListInterfacesComponent,
    ToastsContainer
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    ChartsModule,
    FileUploadModule,
    NgSelectModule,
    FormsModule,
    DataTablesModule,
    NgxDatatableModule,
    FontAwesomeModule,
    CKEditorModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
