import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { faAddressBook, faPlusSquare, faPencilAlt, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.scss'],
  providers: [NgbTypeaheadConfig]
})
export class ProspectComponent implements OnInit {
  ////font-awesome icon
  faAddressBook = faAddressBook;
  faPlusSquare = faPlusSquare;
  faPencilAlt = faPencilAlt;
  faSave = faSave;
  faTrashAlt = faTrashAlt;
  ////
  createProspectForm: FormGroup;
  addAdresseForm: FormGroup;
  submitted = false;
  success = false;
  resultGouv = [];
  dealers;
  selectedDealer;
  prospects;
  items;
  itemsFiltered;
  prospectAdresse;
  prospectAdresseFiltered;
  closeResult: string;
  modalCreateProspect;
  modalGestionAdresse;
  modalAddAdresse;
  isEditable = {};
  isEditableAdresse = {};

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, config: NgbTypeaheadConfig, private modalService: NgbModal) { }

  ngOnInit() {
    this.createProspectForm = this.formBuilder.group({
      raisonSocial: [null, Validators.required],
      siret: [null, Validators.required],
      naf: [null, Validators.required],
      nomRepresentant: [null, Validators.required],
      prenomRepresentant: [null, Validators.required],
      tel: [null, Validators.required],
      email: [null, Validators.required],
      adresse: [null, Validators.required]
    });
    this.createProspectForm.controls['adresse'].valueChanges.subscribe(data => {
      if(data != ''){
        let term = (data.label)?data.label.replace(" ", "+"):data.replace(" ", "+")
        //console.log("chnge", data.replace(" ", "+"), 'https://api-adresse.data.gouv.fr/search/?q='+data.replace(" ", "+")+'&limit=15');
        this.httpClient.get('https://api-adresse.data.gouv.fr/search/?q='+term+'&limit=15')
        .subscribe((data : any) =>{
          let result = [];
          for (let item in data.features){
            //console.log(data.features[item].properties.label);
            result.push({
              "label": data.features[item].properties.label,
              "housenumber": data.features[item].properties.housenumber,
              "postcode": data.features[item].properties.postcode,
              "citycode": data.features[item].properties.citycode,
              "city": data.features[item].properties.city,
              "street": data.features[item].properties.street,
              "x": data.features[item].properties.x,
              "y": data.features[item].properties.y
            });
          }
          this.resultGouv = [...result];
          //console.log("resGouv",this.resultGouv);
        })
      }
    })
    this.addAdresseForm = this.formBuilder.group({
      labelprospect_site: [null, Validators.required],
      adresse: [null, Validators.required]
    });
    this.addAdresseForm.controls['adresse'].valueChanges.subscribe(data => {
      if(data != ''){
        let term = (data.label)?data.label.replace(" ", "+"):data.replace(" ", "+")
        //console.log("chnge", data.replace(" ", "+"), 'https://api-adresse.data.gouv.fr/search/?q='+data.replace(" ", "+")+'&limit=15');
        this.httpClient.get('https://api-adresse.data.gouv.fr/search/?q='+term+'&limit=15')
        .subscribe((data : any) =>{
          let result = [];
          for (let item in data.features){
            //console.log(data.features[item].properties.label);
            result.push({
              "label": data.features[item].properties.label,
              "housenumber": data.features[item].properties.housenumber,
              "postcode": data.features[item].properties.postcode,
              "citycode": data.features[item].properties.citycode,
              "city": data.features[item].properties.city,
              "street": data.features[item].properties.street,
              "x": data.features[item].properties.x,
              "y": data.features[item].properties.y
            });
          }
          this.resultGouv = [...result];
          //console.log("resGouv",this.resultGouv);
        })
      }
    })

    this.getDealer();
    this.getProspects();
  }

  onSubmit() {
    console.log(this.selectedDealer);
    console.log(this.createProspectForm.value);
    this.submitted = true;
    console.log(this.submitted, this.createProspectForm.controls.raisonSocial.errors);
    if (this.createProspectForm.invalid) {
        console.log("form invalide");
        return;
    }
    this.success = true;
    console.log("form valid");
    this.addProspect(this.createProspectForm.value);
    this.modalCreateProspect.close();
  }

  onSubmitAddAdresse(idprospect) {
    console.log(this.addAdresseForm.value);
    this.submitted = true;
    if (this.addAdresseForm.invalid) {
        return;
    }
    this.success = true;
    this.addAdresseForm.value.adresse.labelprospect_site = this.addAdresseForm.value.labelprospect_site;
    this.addAdresse(this.addAdresseForm.value.adresse, idprospect);
    this.modalAddAdresse.close();
  }

  addProspect(data){
    let prospect = data;
    let adresse = data.adresse;
    adresse.labelprospect_site = "Principal";
    delete prospect.adresse;
    prospect.iddealer = this.selectedDealer.iddealer;
    console.log(prospect, adresse);
    this.httpClient
    .post(`${environment.apiUrl}/prospect`, prospect)
    .subscribe(
      (res) => {
        this.addAdresse(adresse, res[0].idprospect);
        this.getProspects();
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  addAdresse(adresse, idprospect){
    adresse.idprospect = idprospect;
    console.log("add adresse", adresse);
    this.httpClient
    .post(`${environment.apiUrl}/prospect/site`, adresse)
    .subscribe(
      (res) => {
        this.getProspectAdresse(idprospect);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }


  formatter = (state: any) => state.label;

  searchAdresse = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.resultGouv)
    )

  searchDealerById(id){
    if(this.dealers){
      let dealer = this.dealers.filter(v => v.iddealer == id);
      return dealer[0].companynamedealer;
    }
  }

  getDealer(){
    this.httpClient
    .get(`${environment.apiUrl}/dealer`)
    .subscribe(
      (data) => {
        this.dealers = data;
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  getProspects(){
    this.httpClient
    .get(`${environment.apiUrl}/prospect`)
    .subscribe(
      (data) => {
        this.items = data;
        this.items = [...this.items];
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  getProspectAdresse(idpropsect){
    this.httpClient
    .get(`${environment.apiUrl}/prospect/site/byprospect/`+idpropsect)
    .subscribe(
      (data) => {
        this.prospectAdresse = data;
        this.prospectAdresse = [...this.prospectAdresse];
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  open(content) {
    this.modalCreateProspect = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    this.modalCreateProspect.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openAdresse(content, row) {
    console.log(row);
    this.getProspectAdresse(row.idprospect);
    this.modalGestionAdresse = this.modalService.open(content, {size: 'xl', ariaLabelledBy: 'modal-basic-title'});
    this.modalGestionAdresse.idprospect = row.idprospect;
    this.modalGestionAdresse.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openAddAdresse(content, idprospect) {
    this.modalAddAdresse = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
    this.modalAddAdresse.idprospect = idprospect;
    this.modalAddAdresse.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    console.log(reason);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason == 'Save click') {
      console.log(reason);
    } else {
      return  `with: ${reason}`;
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.items.filter(function(d) {
      return d.companynameprospect.toLowerCase().indexOf(val) !== -1 ||
      d.lastnameprospect.toLowerCase().indexOf(val) !== -1 ||
      d.firstnameprospect.toLowerCase().indexOf(val) !== -1 ||
      d.mailprospect.toLowerCase().indexOf(val) !== -1 ||
      d.siretnumberprospect.toLowerCase().indexOf(val) !== -1 ||
      d.nafnumberprospect.toLowerCase().indexOf(val) !== -1 ||
      d.idprospect.toLowerCase().indexOf(val) !== -1 ||
      d.nafnumberprospect.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.itemsFiltered = temp;
  }

  updateAdresseFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.prospectAdresse.filter(function(d) {
      return d.labelprospect_site.toLowerCase().indexOf(val) !== -1 ||
      d.adressprospect_site.toLowerCase().indexOf(val) !== -1 ||
      d.zipcodeprospect_site.toLowerCase().indexOf(val) !== -1 ||
      d.inseecodeprospect_site.toLowerCase().indexOf(val) !== -1 ||
      d.cityprospect_site.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.prospectAdresseFiltered = temp;
  }

  // Save row
  saveAdresse(row, rowIndex){
    this.isEditableAdresse[rowIndex]=false;
    console.log("Row saved: "+ rowIndex, row);
    this.httpClient
    .put(`${environment.apiUrl}/prospect/site/`+row.idprospect_site, row)
    .subscribe(
      (res) => {
        this.getProspectAdresse(row.idprospect);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  // Delete row
  deleteAdresse(row, rowIndex){
    this.isEditableAdresse[rowIndex]=!this.isEditableAdresse[rowIndex]
    console.log("Row deleted: "+ rowIndex);
    this.httpClient
    .delete(`${environment.apiUrl}/prospect/site/`+row.idprospect_site)
    .subscribe(
      (res) => {
        this.getProspectAdresse(row.idprospect);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  // Save row
  saveProspect(row, rowIndex){
    this.isEditable[rowIndex]=false;
    console.log("Row saved: "+ rowIndex, row);
    this.httpClient
    .put(`${environment.apiUrl}/prospect/`+row.idprospect, row)
    .subscribe(
      (res) => {
        this.getProspects();
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

  // Delete row
  deleteProspect(row, rowIndex){
    this.isEditable[rowIndex]=!this.isEditable[rowIndex]
    console.log("Row deleted: "+ rowIndex);
    this.httpClient
    .delete(`${environment.apiUrl}/prospect/`+row.idprospect)
    .subscribe(
      (res) => {
        this.getProspects();
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

}
