import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

import listes from './data.json';

@Component({
  selector: 'app-list-interfaces',
  templateUrl: './list-interfaces.component.html',
  styleUrls: ['./list-interfaces.component.scss']
})
export class ListInterfacesComponent implements OnInit {
  listes = listes;
  listesFiltered;
  constructor(private http: HttpClient) {}

  ngOnInit() {}

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.listes.filter(function(d) {
      return d.name.toLowerCase().indexOf(val.toLowerCase()) !== -1 ||
      d.link.toLowerCase().indexOf(val.toLowerCase()) !== -1 ||
      d.describe.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
    });
    this.listesFiltered = temp;
  }

}
