import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from '../../../environments/environment';

const jwtHelper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getCurrentUserValue() {
    return this.currentUserSubject.value;
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  decodeToken(){
    if(this.currentUserSubject.value)
      return jwtHelper.decodeToken(this.currentUserSubject.value.token);
    return null;
  }

  login(username: string, password: string, rememberMe: boolean) {
    return this.http.post<any>(`${environment.apiUrl}/user/login`, { username: username, password: password, rememberMe: rememberMe })
    .pipe(map(user => {
      //console.log(user);
      user.username = username;
      user.token = user.id_token;
      delete user.id_token;
      //const token = user.id_token;
      //user = jwtHelper.decodeToken(token);
      //user.rememberMe = rememberMe;
      //user.id_token = token;
      //console.log(user);
      //console.log(jwtHelper.decodeToken(user.token));
      //console.log(jwtHelper.getTokenExpirationDate(user.token));
      //console.log(jwtHelper.isTokenExpired(user.token));
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      //let client = this.clientService.getByIdUser(user.id);
      this.currentUserSubject.next(user);
      return user;
    }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
