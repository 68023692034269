import { Component, OnInit, Injectable } from '@angular/core';
/*import { Socket } from 'ngx-socket-io';*/
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
@Injectable()
export class DashboardComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    tooltips: {
			mode: 'index',
      intersect: false
		},
    responsive: true,
    scales:{
      xAxes:[{
        stacked:true
      }],
      yAxes:[{
        stacked:true
      }]
    }
  };
  /*public barChartColors: Color[] = [
    { backgroundColor: 'red' },
    { backgroundColor: 'green' },
    { backgroundColor: 'yellow' },
    { backgroundColor: 'gray' },
    { backgroundColor: 'orange' },
    { backgroundColor: 'green' }
  ]*/
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'totalphoneplanprice' },
    { data: [], label: 'totalcallprice' },
    { data: [], label: 'Internet' },
    { data: [], label: 'Sauvegarde' },
    { data: [], label: 'Téléphonie' },
    { data: [], label: 'Réseau' }
  ];
  public dealers = [];
  public selectedDealers = 0;
  constructor(private httpClient: HttpClient) { } /*private socket: Socket, */

  ngOnInit() {
    //this.getDealers();
    //this.getCA();
    /*this.socket.on('Oh hii!', function (data) {
      console.log("recv :  Oh hii!");
      this.emit('burp', { my: 'data' });
    });
    this.socket.on('message', function (data){
      console.log(data);
      let msg = data.msg;
      if(msg == "Oh hii!"){
        console.log('his Hi');
        this.emit('message', { msg: 'burp' });
      }
    });*/
  }
  onChange(value: number) {
      /*this.selectedDealers = value;
      this.barChartLabels = [];
      this.barChartData.forEach(data => {
        data.data = [];
      });*/
      //this.getCA();
  }

  getDealers() {
    /*this.httpClient
    .get('http://localhost:3000/dealers')
    .subscribe(
      (data) => {
        this.dealers = data;
        console.log(this.dealers);
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
    this.httpClient
    .get('http://localhost:3000/invoiceServiceCA')
    .subscribe(
      (data) => {
        data.forEach(stat=> {
          this.barChartData[this.barChartData.findIndex(x=>x.label === stat.category)].data[this.barChartLabels.findIndex(x=>x === stat.year.toString()+" "+stat.month.toString())] = stat.servicepricewhitouttax;
        });
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );*/
  }

  getCA() {
    /*this.httpClient
    .get('http://localhost:3000/invoiceCA?id='+this.selectedDealers.toString())
    .subscribe(
      (data) => {
        data.forEach(stat=> {
            this.barChartLabels.push(stat.year.toString()+" "+stat.month.toString());
            this.barChartData[this.barChartData.findIndex(x=>x.label === 'totalphoneplanprice')].data.push(stat.totalphoneplanprice);
            this.barChartData[this.barChartData.findIndex(x=>x.label === 'totalcallprice')].data.push(stat.totalcallprice);
        });
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
    this.httpClient
    .get('http://localhost:3000/invoiceServiceCA?id='+this.selectedDealers.toString())
    .subscribe(
      (data) => {
        data.forEach(stat=> {
          this.barChartData[this.barChartData.findIndex(x=>x.label === stat.category)].data[this.barChartLabels.findIndex(x=>x === stat.year.toString()+" "+stat.month.toString())] = stat.servicepricewhitouttax;
        });
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );*/
  }

}
