import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-stockaddmodel',
  templateUrl: './stockaddmodel.component.html',
  styleUrls: ['./stockaddmodel.component.scss']
})
export class StockaddmodelComponent implements OnInit {
  addModelForm: FormGroup;
  submitted = false;
  success = false;

  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient) { }

  ngOnInit() {
    this.addModelForm = this.formBuilder.group({
      label: ['', Validators.required],
      prixAchat: [null, Validators.required],
      codeBar: [null],
      description: [null],
      categorie: [null]
    });
  }

  onSubmit() {
    console.log(this.addModelForm.value);
    this.submitted = true;
    if (this.addModelForm.invalid) {
        return;
    }
    this.success = true;
    this.addproduct(this.addModelForm.value);
  }

  addproduct(product) {
    console.log(product);
    this.httpClient
    .post('http://localhost:3000/addproduct', product)
    .subscribe(
      () => {
        console.log('Enregistrement terminé !');
      },
      (error) => {
        console.log('Erreur ! : ' + error.message);
      }
    );
  }

}
