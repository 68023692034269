import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevisComponent } from './devis/devis.component';
import { StockComponent } from './stock/stock.component';
import { ImportConfigComponent } from './import-config/import-config.component';
import { CentrexAudioComponent } from './centrex-audio/centrex-audio.component';
import { AboutComponent } from './about/about.component';
import { ProspectComponent } from './prospect/prospect.component';
import { LoginComponent } from './login/login.component';
import { ListInterfacesComponent } from './list-interfaces/list-interfaces.component';

import { AuthGuard } from './helpers/auth/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'devis', component: DevisComponent, canActivate: [AuthGuard] },
  { path: 'prospect', component: ProspectComponent, canActivate: [AuthGuard] },
  { path: 'list-interfaces', component: ListInterfacesComponent, canActivate: [AuthGuard] },
  { path: 'stock', component: StockComponent, canActivate: [AuthGuard] },
  { path: 'import-config', component: ImportConfigComponent, canActivate: [AuthGuard] },
  { path: 'centrex-audio', component: CentrexAudioComponent, canActivate: [AuthGuard] },
  { path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
